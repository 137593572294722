import Vue from "vue";
import Router from "vue-router";
import { Message } from "element-ui";
import { hasPatientChartAccess } from "./router-guard";
import MainContent from "@/components/common/MainContent.vue";
import HomePageLayout from "@/views/layout-v2/HomePageLayout.vue";
import NotFound from "@/components/common/NotFound.vue";
import NoSearchResults from "@/components/common/NoSearchResults.vue";
import LogIn from "@/views/LogIn.vue";
import AuthCallback from "@/views/AuthCallback.vue";
import Support from "@/views/Support.vue";
import SupportOptions from "@/components/support/SupportOptions.vue";
import EULA from "@/views/EULA.vue";
import store from "@/store";

// new marketplace routes
import Search from "@/views/marketplace/Search.vue";
import SearchList from "@/views/marketplace/SearchList.vue";
import Product from "@/views/marketplace/Product.vue";
import Category from "@/views/marketplace/Category.vue";
import MarketplaceMyStockroom from "@/components/marketplace/MarketplaceMyStockroom.vue";
import MarketplaceMyStockroomApps from "@/components/marketplace/MarketplaceMyStockroomApps.vue";
import OrderHistory from "@/components/marketplace/my-stockroom/order-history/components/OrderHistory.vue";
import MarketplaceReOrder from "@/components/marketplace/MarketplaceReOrder.vue";
import MarketplaceAutoShipment from "@/components/marketplace/MarketplaceAutoShipment.vue";
import MarketplaceMyStockroomGrid from "@/components/marketplace/common/MarketplaceMyStockroomGrid.vue";
import MarketplaceMyStockroomList from "@/components/marketplace/common/MarketplaceMyStockroomList.vue";
import VendorStore from "@/components/dashboard/vendor/VendorStore.vue";
import Settings from "@/components/practice-builder/Settings.vue";
// Use import method to help webpack codesplit our routes

// Org Chunk
const OrgDashboard = () => {
  return import(/* webpackChunkName: "org" */ "@/components/dashboard/org/OrgDashboard.vue");
};
const OrgLocations = () => {
  return import(/* webpackChunkName: "org" */ "@/components/dashboard/org/OrgLocations.vue");
};
const OrgUserManagement = () => {
  return import(
    /* webpackChunkName: "OrgUserManagement" */ "@/components/dashboard/org-user-management/OrgUserManagement.vue"
  );
};
const OrgAnalytics = () => {
  return import(/* webpackChunkName: "org" */ "@/components/dashboard/org/OrgAnalytics.vue");
};
const OrgSubscriptions = () => {
  return import(/* webpackChunkName: "org" */ "@/components/dashboard/org/OrgSubscriptions.vue");
};
const OrgPaymentMethods = () => {
  return import(/* webpackChunkName: "org" */ "@/components/dashboard/org/OrgPaymentMethods.vue");
};
const OrgACHPayout = () => {
  return import(/* webpackChunkName: "org" */ "@/components/dashboard/org/OrgACHPayout.vue");
};

const PracticeReceipts = () => {
  return import(
    /* webpackChunkName: "PracticeReceipts" */ "@/components/admin-panel/PracticeReceipts/PracticeReceipts.vue"
  );
};

const SubscriptionReceipts = () => {
  return import(
    /* webpackChunkName: "SubscriptionReceipts" */ "@/components/shared-components/Invoice/Receipts/SubscriptionReceipts.vue"
  );
};

const OrgReceipts = () => {
  return import(/* webpackChunkName: "org" */ "@/components/dashboard/org/OrgReceipts.vue");
};
const OrgContactInfo = () => {
  return import(/* webpackChunkName: "org" */ "@/components/dashboard/org/OrgContactInfo.vue");
};
const ActiveSubscription = () => {
  return import(/* webpackChunkName: "org" */ "@/components/dashboard/org/ActiveSubscription.vue");
};
const OrgToken = () => {
  return import(/* webpackChunkName: "org" */ "@/components/dashboard/org/OrgToken.vue");
};

// Home chunks
const Home = () => {
  return import(/* webpackChunkName: "home" */ "@/views/Home.vue");
};

// Location Chunk
const LocationDashboard = () => {
  return import(/* webpackChunkName: "LocationDashboard" */ "@/components/dashboard/LocationDashboard.vue");
};
const LocationOrders = () => {
  return import(/* webpackChunkName: "LocationOrders" */ "@/components/dashboard/LocationOrders.vue");
};
const PatientsApp = () => {
  return import(/* webpackChunkName: "PatientsApp" */ "@/components/apps/PatientsApp.vue");
};
const LocationSettings = () => {
  return import(
    /* webpackChunkName: "LocationSettings" */ "@/components/admin-panel/Locations/LocationSettings/LocationSettings.vue"
  );
};
const PatientDetails = () => {
  return import(/* webpackChunkName: "patientChart" */ "@/components/dashboard/patient-chart/PatientDetails.vue");
};
const PatientList = () => {
  return import(/* webpackChunkName: "patientChart" */ "@/components/dashboard/patient-chart/PatientList.vue");
};

const PlaceOrder503A = () => {
  return import(
    /* webpackChunkName: "patientChart" */ "@/components/dashboard/patient-chart/Prescription/PlaceOrder/PlaceOrder.vue"
  );
};

const SearchProduct503A = () => {
  return import(
    /* webpackChunkName: "SearchProduct503A" */ "@/components/dashboard/patient-chart/Prescription/AddPrescriptions/SearchProduct/SearchProduct.vue"
  );
};

const DosingHome = () => {
  return import(/* webpackChunkName: "PatientChart" */ "@/components/apps/DosingHome.vue");
};
const AppointmentDetails = () => {
  return import(
    /* webpackChunkName: "AppointmentDetails" */ "@/components/dashboard/patient-chart/AppointmentDetails.vue"
  );
};
const DosingApp = () => {
  return import(/* webpackChunkName: "DosingApp" */ "@/components/apps/DosingApp.vue");
};
const PatientAppointments = () => {
  return import(
    /* webpackChunkName: "PatientAppointments" */ "@/components/dashboard/patient-chart/PatientAppointments.vue"
  );
};
const DosingConsultations = () => {
  return import(/* webpackChunkName: "DosingConsultations" */ "@/components/apps/DosingConsultations.vue");
};
const MarketplaceDashboard = () => {
  return import(/* webpackChunkName: "MarketplaceDashboard" */ "@/components/dashboard/MarketplaceDashboard.vue");
};
const MarketplaceDashboardHome = () => {
  return import(
    /* webpackChunkName: "MarketplaceDashboardHome" */ "@/components/dashboard/MarketplaceDashboardHome.vue"
  );
};
const MarketplaceProducts = () => {
  return import(/* webpackChunkName: "MarketplaceProducts" */ "@/components/dashboard/MarketplaceProducts.vue");
};

// Marketplace Chunk
const Marketplace = () => {
  return import(/* webpackChunkName: "marketplace" */ "@/views/Marketplace.vue");
};
const NewMarketplaceHome = () => {
  return import(/* webpackChunknName: "marketplace" */ "@/views/NewMarketplaceHome.vue");
};
const NewCheckout = () => {
  return import(/* webpackChunkName: "marketplace" */ "@/views/NewCheckout.vue");
};
const CheckoutSuccess = () => {
  return import(/* webpackChunkName: "marketplace" */ "@/views/CheckoutSuccess.vue");
};
const PatientProfileInfo = () => {
  return import(/* webpackChunkName: "marketplace" */ "@/components/common/PatientProfileInfo.vue");
};
// import PatientProfileInfo from '';

// covid-19
const Covid19 = () => {
  return import(/* webpackChunkName: "marketplace" */ "@/views/Covid19.vue");
};

// Vendor Chunk
const VendorDashboard = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/VendorDashboard.vue");
};
const VendorOrders = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/VendorOrders.vue");
};
const VendorOrderList = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/VendorOrderList.vue");
};
const VendorInvoice = () => {
  return import(/* webpackChunkName: "VendorInvoice" */ "@/components/vendor/order/VendorInvoice/VendorInvoice.vue");
};
const VendorProducts = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/VendorProducts.vue");
};
const VendorTiers = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/VendorTiers.vue");
};
const VendorRegistrations = () => {
  return import(
    /* webpackChunkName: "VendorRegistrations" */ "@/components/vendor-module/registrations/VendorRegistrations.vue"
  );
};
const VendorUsers = () => {
  return import(
    /* webpackChunkName: "seller" */ "@/components/dashboard/vendor/vendor-user-management/VendorUsers.vue"
  );
};
const ActiveInventory = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/ActiveInventory.vue");
};
const FulfilledInventory = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/FulfilledInventory.vue");
};

const VendorSettings = () => {
  return import(/* webpackChunkName: "VendorSettings" */ "@/components/vendor-module/settings/VendorSettings.vue");
};
const WarehouseInventory = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/WarehouseInventory.vue");
};

// Admin Chunk
const SimpatraAdmin = () => {
  return import(/* webpackChunkName: "admin" */ "@/views/SimpatraAdmin.vue");
};
const SimpatraConsultations = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/apps/SimpatraConsultations.vue");
};
const VendorManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/VendorManagement.vue");
};
const OrganizationManagament = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/OrgManagement.vue");
};
// const OrganizationManagamentDetails = () => {
//   return import(/* webpackChunkName: "admin" */ "@/components/admin/OrganizationManagement/OrgManagementDetails.vue");
// };
const OrganizationList = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/OrganizationManagement/OrgList.vue");
};
const SubscriptionManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/SubscriptionManagement.vue");
};
const DirectoryManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/DirectoryManagement.vue");
};

const OrgSubscriptionDetails = () => {
  return import("@/components/dashboard/org/OrgSubscriptionDetails.vue");
};
const UserManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/UserManagement.vue");
};
const QuizManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/QuizManagement.vue");
};

// Practice Builder
const OnlineStoreMain = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/practice-builder/OnlineStoreMain.vue");
};
const PracticeBuilderApp = () => {
  return import(/* webpackChunkName: "location" */ "@/components/practice-builder/PracticeBuilderApp.vue");
};
const PracticeBuilderDashboard = () => {
  return import(/* webpackChunkName: "location" */ "@/components/dashboard/PracticeBuilderDashboard.vue");
};
const PracticeBuilderLandingPagesDashboard = () => {
  return import(
    /* webpackChunkName: "location" */ "@/components/practice-builder/patient-outreach/landing-pages/PracticeBuilderLandingPagesDashboard.vue"
  );
};
const PatientOutReachApps = () => {
  return import(
    /* webpackChunkName: "location" */ "@/components/practice-builder/patient-outreach/PatientOutReachApps.vue"
  );
};
const PatientOutReach = () => {
  return import(
    /* webpackChunkName: "location" */ "@/components/practice-builder/patient-outreach/PatientOutReach.vue"
  );
};
const LandingPagesDashboard = () => {
  return import(
    /* webpackChunkName: "location" */ "@/components/practice-builder/patient-outreach/landing-pages/LandingPagesDashboard.vue"
  );
};
const SocialMediaPageDashboard = () => {
  return import("@/components/practice-builder/patient-outreach/landing-pages/SocialMediaPageDashboard.vue");
};
const PatientFormsPage = () => {
  return import("@/components/practice-builder/patient-outreach/landing-pages/PatientFormsPage.vue");
};

// med-center
const MedCenterApp = () => {
  return import(/* webpackChunkName: "MedCenterApp" */ "@/components/med-center/MedCenterApp.vue");
};
const MedCenterHome = () => {
  return import(/* webpackChunkName: "MedCenterHome" */ "@/components/med-center/MedCenterHome.vue");
};
// const MedCenterOrdersLab = () => { return import(/* webpackChunkName: "medcenter" */ '@/components/med-center/MedCenterOrdersLab.vue'); };
const MedCenterPatientResults = () => {
  return import(
    /* webpackChunkName: "MedCenterPatientResults" */ "@/components/med-center/MedCenterPatientResults.vue"
  );
};
const MedCenterPatientChart = () => {
  return import(/* webpackChunkName: "MedCenterPatientChart" */ "@/components/med-center/MedCenterPatientChart.vue");
};

const ApprovalRequireForLabPatients = () => {
  return import(/* webpackChunkName: "medcenter" */ "@/components/med-center/ApprovalRequireForLabPatients.vue");
};
const PendingLabResultPatients = () => {
  return import(/* webpackChunkName: "medcenter" */ "@/components/med-center/PendingLabResultPatients.vue");
};
const CompletedLabResultPatients = () => {
  return import(/* webpackChunkName: "medcenter" */ "@/components/med-center/CompletedLabResultPatients.vue");
};
const MedCenterPatientLabOrders = () => {
  return import(/* webpackChunkName: "medcenter" */ "@/components/med-center/MedCenterPatientLabOrders.vue");
};
const MedCenterSettings = () => {
  return import(/* webpackChunkName: "medcenter" */ "@/components/med-center/MedCenterSettings.vue");
};
const LabOrderInvoiceDetails = () => {
  return import(/* webpackChunkName: "medcenter" */ "@/components/med-center/LabOrderInvoiceDetails.vue");
};
const LabOrderPatientInvoiceDetails = () => {
  return import(/* webpackChunkName: "medcenter" */ "@/components/med-center/LabOrderPatientInvoiceDetails.vue");
};
const LocationActivationList = () => {
  return import(/* webpackChunkName: "medcenter" */ "@/components/med-center/LocationActivationList.vue");
};
const LocationOrderDetails = () => {
  return import(/* webpackChunkName: "medcenter" */ "@/components/med-center/LocationOrderDetails.vue");
};
const LocationMedcenterOrders = () => {
  return import(/* webpackChunkName: "medcenter" */ "@/components/med-center/LocationMedcenterOrders.vue");
};

// LAYOUT-V2
const HomePage = () => {
  return import(/* webpackChunkName: "homepage" */ "@/components/layout-v2/HomePage.vue");
};
const MarketplaceV2 = () => {
  return import(/* webpackChunkName: "marketplace" */ "@/components/layout-v2/Marketplace.vue");
};
const MedCenter = () => {
  return import(/* webpackChunkName: "medcenter" */ "@/components/layout-v2/MedCenter.vue");
};
const Intellipel = () => {
  return import(/* webpackChunkName: "intellipel" */ "@/components/layout-v2/Intellipel.vue");
};
const PracticeBuilder = () => {
  return import(/* webpackChunkName: "practiceBuilder" */ "@/components/layout-v2/PracticeBuilder.vue");
};
const ContactUs = () => {
  return import(/* webpackChunkName: "contactUs" */ "@/components/layout-v2/ContactUs.vue");
};

const MyProfileView = () => {
  return import(/* webpackChunkName: "MyProfileView" */ "@/views/my-profile/MyProfile.vue");
};
const MyProfile = () => {
  return import(/* webpackChunkName: "MyProfile" */ "@/components/my-profile/MyProfile.vue");
};

const AdminReport = () => {
  return import(/* webpackChunkName: "AdminReport" */ "@/components/admin-report/AdminReport.vue");
};

const Onboarding = () => {
  return import(/* webpackChunkName: "onboarding" */ "@/components/onboarding/Onboarding.vue");
};

const OnboardingForm = () => {
  return import(/* webpackChunkName: "onboarding" */ "@/components/onboarding/OnboardingForm/OnboardingForm.vue");
};

const VendorInformation = () => {
  return import(
    /* webpackChunkName: "onboarding" */ "@/components/onboarding/VendorOnboardingForm/components/VendorInformation.vue"
  );
};

const PracticeInformation = () => {
  return import(
    /* webpackChunkName: "onboarding" */ "@/components/onboarding/PracticeOnboardingForm/components/PracticeInformation.vue"
  )
}

const AddressInformation = () => {
  return import(
    /* webpackChunkName: "onboarding" */ "@/components/onboarding/shared/components/AddressInformation.vue"
  );
};

const BusinessAssociateAgreement = () => {
  return import(
    /* webpackChunkName: "BusinessAssociateAgreement" */ "@/components/onboarding/shared/components/BusinessAssociateAgreement.vue"
  );
};

const VendorLicenseAgreement = () => {
  return import(
    /* webpackChunkName: "onboarding" */ "@/components/onboarding/VendorOnboardingForm/components/LicenseAgreement.vue"
  );
};

const AccountSetup = () => {
  return import(
    /* webpackChunkName: "onboarding" */ "@/components/onboarding/shared/components/AccountSetup.vue"
  );
};

const OnboardingEULA = () => {
  return import(
    /* webpackChunkName: "onboarding" */ "@/components/onboarding/shared/components/EULA.vue"
  );
}

const GetStarted = () => {
  return import(
    /* webpackChunkName: "onboarding" */ "@/components/onboarding/shared/components/GetStarted.vue"
  );
};


const SetAppointment = () => {
  return import(
    /* webpackChunkName: "SetAppointment" */ "@/components/onboarding/shared/components/SetAppointment.vue"
  );
};

const Signup = () => {
  return import(/* webpackChunkName: "Signup" */ "@/components/signup/Signup.vue");
};

const SetPassword = () => {
  return import(/* webpackChunkName: "SetPassword" */ "@/components/password/SetPassword.vue");
};

const CreatePassword = () => {
  return import(/* webpackChunkName: "CreatePassword" */ "@/components/password/CreatePassword.vue");
};

const PasswordRecovery = () => {
  return import(/* webpackChunkName: "PasswordRecovery" */ "@/components/password/PasswordRecovery.vue");
};

const ReportOrder = () => {
  return import(/* webpackChunkName: "ReportOrder" */ "@/components/admin-report/ReportOrder/ReportOrder.vue");
};

const ReportsList = () => {
  return import(/* webpackChunkName: "ReportsList" */ "@/components/admin-report/ReportsList/ReportsList.vue");
};

const PreOrdersCart = () => {
  return import(/* webpackChunkName: "marketplace" */ "@/components/marketplace/PreOrdersCart/PreOrdersCart.vue");
};

const Privacy = () => {
  return import(/* webpackChunkName: "privacy" */ "@/views/privacy.vue");
}

const TermsOfService = () => {
  return import(/* webpackChunkName: "termsOfService" */ "@/views/terms-of-service.vue");
}

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: MainContent,
      children: [
        {
          path: "/",
          name: "Home",
          component: Home,
        },
        {
          path: "covid19-medical-supplies",
          name: "Covid19",
          component: Covid19,
        },
        {
          path: "covid19-medical-supplies-success",
          name: "Covid19Success",
          component: Covid19,
        },
        {
          path: "login",
          name: "login",
          component: LogIn,
        },
        // Terms and conditions
        // {
        //   path: '/tos/MPLA',
        //   name: 'MPLA',
        //   component: MPLA,
        // },
        {
          path: "/tos/EULA",
          name: "EULA",
          component: EULA,
        },
        // {
        //   path: '/tos/BAA',
        //   name: 'BAA',
        //   component: BAA,
        // },
        // Support
        {
          path: "/support",
          component: Support,
          children: [
            {
              path: "/",
              name: "Support",
              component: SupportOptions,
            },
            {
              path: "consults",
              name: "DosingConsultations",
              component: DosingConsultations,
              meta: { locationRequired: true },
            },
            {
              path: "simpatra-consults",
              name: "SimpatraConsultations",
              component: SimpatraConsultations,
              meta: { authRequired: true },
            },
          ],
        },
        {
          path: "/simpatra",
          name: "SimpatraAdmin",
          component: SimpatraAdmin,
          meta: { authRequired: true, simpatraAdmin: true },
          children: [
            {
              path: "vendor",
              component: VendorManagement,
              name: "VendorManagement",
              props: true,
            },
            {
              path: "organization",
              component: OrganizationManagament,
              props: true,
              children: [
                {
                  path: "/",
                  component: OrganizationList,
                  name: "OrganizationManagement",
                  props: true,
                },
                // deprecated
                // {
                //   path: ':orgId',
                //   component: OrganizationManagamentDetails,
                //   name: 'OrganizationManagementDetails',
                //   props: true,
                // },
              ],
            },
            {
              path: "subscription",
              component: SubscriptionManagement,
              name: "SubscriptionManagement",
              props: true,
            },
            {
              path: "directory",
              component: DirectoryManagement,
              name: "DirectoryManagement",
              props: true,
            },
            {
              path: "directory",
              component: DirectoryManagement,
              // meta: { orgAdmin: true, authRequired: true },
              children: [
                {
                  path: "providers",
                  component: UserManagement,
                  name: "UserManagement",
                },
                {
                  path: "quiz-details",
                  component: QuizManagement,
                  name: "QuizManagement",
                  props: true,
                },
              ],
            },
            {
              path: "admin-report",
              meta: { simpatraAdminAccessRequired: true, simpatraReports: true },
              component: AdminReport,
              children: [
                {
                  path: "/",
                  component: ReportsList,
                  name: "ReportsList",
                  props: true,
                },
                {
                  path: "purchase/:generatedId",
                  component: ReportOrder,
                  name: "ReportOrder",
                  props: true,
                },
                {
                  path: "subscription/:subscriptionId",
                  component: SubscriptionReceipts,
                  name: "SubscriptionReceipts",
                  props: true,
                },
              ],
            },
          ],
        },
        // --------------------------------
        // BEGIN DASHBOARD CONFIG
        {
          path: "/dashboard/admin",
          component: OrgDashboard,
          meta: { orgAdmin: true, authRequired: true },
          children: [
            {
              path: "users",
              component: OrgUserManagement,
              name: "OrgUserManagement",
            },
            {
              path: "locations",
              component: OrgLocations,
              name: "OrgLocations",
              props: true,
            },
            {
              path: "token",
              component: OrgToken,
              name: "OrgToken",
              props: true,
            },
            {
              path: "analytics",
              component: OrgAnalytics,
              name: "OrgAnalytics",
              props: true,
            },
            {
              path: "laboratory-sales",
              name: "LaboratorySales",
              component: LocationActivationList,
              props: true,
            },
            {
              path: "subscriptions",
              component: OrgSubscriptions,
              name: "OrgSubscriptions",
            },
            {
              path: "payment-methods",
              component: OrgPaymentMethods,
              name: "OrgPaymentMethods",
            },
            {
              path: "ach-methods",
              component: OrgACHPayout,
              name: "OrgACHPayout",
            },
            {
              path: "receipts",
              component: PracticeReceipts,
              children: [
                {
                  path: "list-view",
                  component: OrgReceipts,
                  name: "OrgReceipts",
                },
              ],
            },
            {
              path: "contact-info",
              component: OrgContactInfo,
              name: "OrgContactInfo",
            },
            {
              path: "subscription",
              component: ActiveSubscription,
              name: "ActiveSubscription",
            },
            {
              path: "subscription-details/:subId",
              component: OrgSubscriptionDetails,
              name: "OrgSubscriptionDetails",
              props: true,
            },
          ],
        },
        {
          path: "/dashboard/vendor/:vendorId",
          component: VendorDashboard,
          props: true,
          meta: { authRequired: true, checkVendorAccessibility: true },
          children: [
            {
              path: "orders",
              component: VendorOrders,
              props: true,
              meta: { checkVendorAccessibility: true },
              children: [
                {
                  path: "/",
                  component: VendorOrderList,
                  name: "VendorOrders",
                  props: true,
                  meta: { checkVendorAccessibility: true },
                },
                {
                  path: ":orderId/:purchaseType",
                  component: VendorInvoice,
                  name: "VendorInvoice",
                  props: true,
                  meta: { checkVendorAccessibility: true },
                },
              ],
            },
            {
              path: "products",
              component: VendorProducts,
              name: "VendorProductManagement",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "tiers",
              component: VendorTiers,
              name: "VendorTiers",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "registrations",
              component: VendorRegistrations,
              name: "VendorRegistrations",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "users",
              component: VendorUsers,
              name: "VendorUsers",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "warehouse",
              component: WarehouseInventory,
              name: "WarehouseInventory",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "activeinventory",
              component: ActiveInventory,
              name: "ActiveInventory",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "fulfilledinventory",
              component: FulfilledInventory,
              name: "FulfilledInventory",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "settings",
              component: VendorSettings,
              name: "VendorSettings",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
          ],
        },
        // Location Dashboards
        {
          path: "/dashboard/:locationId",
          component: LocationDashboard,
          props: true,
          meta: { locationRequired: true, authRequired: true },
          children: [
            // DEPRECATED
            /* {
              path: "med-center",
              name: "med-center",
              component: MedCenterApp,
              props: true,
              children: [
                {
                  path: "/",
                  name: "MedCenterHome",
                  component: MedCenterHome,
                  props: true,
                },
                {
                  path: "kit-activation",
                  name: "LocationMedcenterOrders",
                  component: LocationMedcenterOrders,
                  props: true,
                  children: [
                    {
                      path: "/",
                      name: "LocationActivationList",
                      component: LocationActivationList,
                    },
                    {
                      path: "location-order",
                      name: "LocationOrderDetails",
                      component: LocationOrderDetails,
                    },
                  ],
                },
                {
                  path: "patient-chart",
                  name: "MedcenterPatientsApp",
                  component: MedcenterPatientsApp,
                  props: true,
                  children: [
                    {
                      path: ":patientId",
                      name: "MedcenterPatientDetails",
                      component: MedCenterPatientChartDetails,
                      props: true,
                    },
                    // MEDCENTER DYNAMIC INVOICE
                    {
                      path: "lab-order-invoice/:patientId/fulfillment/:fulfillmentId/:invoiceType",
                      name: "LabOrderInvoiceDetails",
                      component: LabOrderInvoiceDetails,
                      props: true,
                    },
                    {
                      path: "lab-order-patient-invoice",
                      name: "LabOrderPatientInvoiceDetails",
                      component: LabOrderPatientInvoiceDetails,
                      props: true,
                    },
                    {
                      path: "/",
                      name: "MedCenterPatientChart",
                      component: MedCenterPatientChart,
                      props: true,
                    },
                  ],
                },

                {
                  path: 'lab-orders',
                  name: 'MedCenterPatientLabOrders',
                  component: MedCenterPatientLabOrders,
                  props: true,
                  children: [
                    {
                      path: 'approval-require',
                      name: 'ApprovalRequireForLabPatients',
                      component: ApprovalRequireForLabPatients,
                      props: true,
                    },
                    {
                      path: 'pending-results',
                      name: 'PendingLabResultPatients',
                      component: PendingLabResultPatients,
                      props: true,
                    },
                    {
                      path: 'completed-results',
                      name: 'CompletedLabResultPatients',
                      component: CompletedLabResultPatients,
                      props: true,
                    },
                  ],
                },
                // UPON CHECKING, THIS SEEMS UNUSABLE ANYMORE
                {
                  path: "patient-results",
                  name: "MedCenterPatientResults",
                  component: MedCenterPatientResults,
                  props: true,
                },
              ],
            },*/

            // dosing
            {
              path: "patient-chart",
              component: DosingApp,
              props: true,
              beforeEnter: (to, from, next) => {
                /**
                 * USER CAN'T GO TO PATIENT CHART
                 * IF SCHEDULING DOES NOT INCLUDED IN THE PERMISSIONS
                 */
                const hasAccess = hasPatientChartAccess();
                if (hasAccess) {
                  next();
                } else {
                  Message({
                    type: "error",
                    showClose: true,
                    message: "You don't have scheduling permission to proceed patient chart!",
                  });
                  next(false);
                }
              },
              children: [
                {
                  path: "/",
                  name: "DosingHome",
                  component: DosingHome,
                  props: true,
                },
                {
                  path: "appointments",
                  name: "PatientAppointments",
                  component: PatientAppointments,
                  props: true,
                },
                {
                  path: "appointments/:appointmentId",
                  name: "AppointmentDetails",
                  component: AppointmentDetails,
                  props: true,
                },
                {
                  path: "patients",
                  component: PatientsApp,
                  beforeEnter: (to, from, next) => {
                    next();
                  },
                  children: [
                    {
                      path: "/",
                      name: "PatientsList",
                      component: PatientList,
                      props: true,
                    },
                    {
                      path: ":patientId",
                      name: "PatientDetails",
                      component: PatientDetails,
                      props: true,
                    },
                    // MEDCENTER DYNAMIC INVOICE
                    {
                      path: "lab-order-invoice/:patientId/fulfillment/:fulfillmentId/:invoiceType",
                      name: "LabOrderInvoiceDetails",
                      component: LabOrderInvoiceDetails,
                      props: true,
                    },
                    {
                      path: ":patientId/location/:locationId/search-product",
                      name: "SearchProduct503A",
                      component: SearchProduct503A,
                      props: true,
                    },
                    {
                      path: ":patientId/location/:locationId/place-order",
                      name: "PlaceOrder503A",
                      component: PlaceOrder503A,
                      props: true,
                    },
                  ],
                },
                {
                  path: "fulfillments",
                  component: LocationMedcenterOrders,
                  props: true,
                  children: [
                    {
                      path: "/",
                      name: "LocationActivationList",
                      component: LocationActivationList,
                    },
                    {
                      path: "location-order",
                      name: "LocationOrderDetails",
                      component: LocationOrderDetails,
                    },
                  ],
                },
                // {
                //   path: "patient-chart",
                //   name: "MedcenterPatientsApp",
                //   component: MedcenterPatientsApp,
                //   props: true,
                //   children: [
                //     {
                //       path: ":patientId",
                //       name: "MedcenterPatientDetails",
                //       component: MedCenterPatientChartDetails,
                //       props: true,
                //     },
                //     // MEDCENTER DYNAMIC INVOICE
                //     {
                //       path: "lab-order-invoice/:patientId/fulfillment/:fulfillmentId/:invoiceType",
                //       name: "LabOrderInvoiceDetails",
                //       component: LabOrderInvoiceDetails,
                //       props: true,
                //     },
                //     {
                //       path: "lab-order-patient-invoice",
                //       name: "LabOrderPatientInvoiceDetails",
                //       component: LabOrderPatientInvoiceDetails,
                //       props: true,
                //     },
                //     {
                //       path: "/",
                //       name: "MedCenterPatientChart",
                //       component: MedCenterPatientChart,
                //       props: true,
                //     },
                //   ],
                // },

                {
                  path: "lab-orders",
                  name: "PatientLabOrders",
                  component: MedCenterPatientLabOrders,
                  props: true,
                  children: [
                    {
                      path: "approval-require",
                      name: "ApprovalRequireForLabPatients",
                      component: ApprovalRequireForLabPatients,
                      props: true,
                    },
                    {
                      path: "pending-results",
                      name: "PendingLabResultPatients",
                      component: PendingLabResultPatients,
                      props: true,
                    },
                    {
                      path: "completed-results",
                      name: "CompletedLabResultPatients",
                      component: CompletedLabResultPatients,
                      props: true,
                    },
                  ],
                },
                {
                  path: "patient-chart-settings",
                  name: "MedCenterSettings",
                  component: MedCenterSettings,
                  props: true,
                },
              ],
            },
            {
              path: "marketplace",
              component: MarketplaceDashboard,
              props: true,
              children: [
                {
                  path: "/",
                  name: "MarketplaceDashboardHome",
                  component: MarketplaceDashboardHome,
                  props: true,
                },
                {
                  path: "my-stock-room",
                  component: MarketplaceMyStockroomApps,
                  props: true,
                  children: [
                    {
                      path: "/",
                      name: "MarketplaceMyStockroom",
                      component: MarketplaceMyStockroom,
                      props: true,
                      children: [
                        {
                          path: "mytock-room-grid",
                          name: "MarketplaceMyStockroomGrid",
                          component: MarketplaceMyStockroomGrid,
                          props: true,
                        },
                        {
                          path: "mytock-room-list",
                          name: "MarketplaceMyStockroomList",
                          component: MarketplaceMyStockroomList,
                          props: true,
                        },
                      ],
                    },
                    {
                      path: "order-history",
                      name: "MarketplaceOrderHistory",
                      component: OrderHistory,
                      props: true,
                    },
                    {
                      path: "re-order",
                      name: "MarketplaceReOrder",
                      component: MarketplaceReOrder,
                      props: true,
                    },
                    {
                      path: "auto-shipment",
                      name: "MarketplaceAutoShipment",
                      component: MarketplaceAutoShipment,
                      props: true,
                    },
                  ],
                },
                {
                  path: "products",
                  name: "MarketplaceProducts",
                  component: MarketplaceProducts,
                  props: true,
                },
                {
                  path: "orders",
                  name: "LocationOrders",
                  component: LocationOrders,
                  props: true,
                },
              ],
            },
            // Practice Builder
            {
              path: "practice",
              component: PracticeBuilderApp,
              props: true,
              meta: { locationRequired: true },
              children: [
                {
                  path: "/",
                  name: "PracticeBuilderDashboard",
                  component: PracticeBuilderDashboard,
                  props: true,
                },
                {
                  path: "Settings",
                  name: "Settings",
                  component: Settings,
                  props: true,
                },

                {
                  path: "analytics",
                  component: OrgAnalytics,
                  name: "OrgAnalytics",
                  props: true,
                },
                {
                  path: "online-store",
                  name: "OnlineStoreMain",
                  component: OnlineStoreMain,
                  props: true,
                  // children: [
                  //   {
                  //     path: 'store-setting',
                  //     name: 'StoreSettingPage',
                  //     component: StoreSettingPage,
                  //     props: true,
                  //     children: [
                  //     ],
                  //   },
                  // ],
                },
                {
                  path: "patient-outreach",
                  component: PatientOutReachApps,
                  props: true,
                  children: [
                    // {
                    //   path: '/',
                    //   name: 'PatientOutReach',
                    //   component: PatientOutReach,
                    //   props: true,
                    //   children: [
                    //   ],
                    // },
                    {
                      path: "landing-pages",
                      name: "PracticeBuilderLandingPagesDashboard",
                      component: LandingPagesDashboard,
                      props: true,
                    },
                    {
                      path: "social-media-page",
                      name: "SocialMediaPageDashboard",
                      component: SocialMediaPageDashboard,
                      props: true,
                    },
                    // {
                    //   path: '/',
                    //   name: 'LandingPages',
                    //   component: PracticeBuilderLandingPagesDashboard,
                    //   props: true,
                    // },
                    {
                      path: "patient-forms",
                      name: "PatientFormsPage",
                      component: PatientFormsPage,
                      props: true,
                    },
                  ],
                },
              ],
            },
            {
              path: "settings/:tabDefault?",
              name: "LocationSettings",
              component: LocationSettings,
              props: true,
            },
          ],
        },
        {
          path: "/marketplace-home",
          name: "NewMarketplaceHome",
          component: NewMarketplaceHome,
          props: true,
        },
        // END DASHBOARD CONFIG
        // ---------------------------------
        // BEGIN MARKETPLACE CONFIG
        {
          path: "/marketplace",
          component: Marketplace,
          meta: { locationRequired: true, marketplace: true },
          children: [
            // {
            //   path: '/',
            //   name: 'NewMarketplaceHome',
            //   component: NewMarketplaceHome,
            // },
            {
              path: "category/:category",
              name: "CategoryPage",
              component: Category,
              props: true,
            },
            {
              path: "category/:category/:subcategory",
              name: "SubCategoryPage",
              component: Category,
              props: true,
            },
            {
              path: "search",
              name: "Search",
              component: Search,
            },
            {
              path: "searchlist",
              name: "SearchList",
              component: SearchList,
            },
            {
              path: ":categorySlug/:vin/:slug",
              name: "Product",
              component: Product,
              props: true,
              meta: {
                scrollPos: {
                  top: 0,
                  left: 0,
                },
              },
            },
            {
              path: "no-results",
              name: "NoSearchResults",
              component: NoSearchResults,
            },
            {
              path: "vendor/:vendorId",
              name: "VendorStore",
              component: VendorStore,
            },
          ],
        },
        // END MARKETPLACE CONFIG
        // --------------------------------
        // BEGIN CHECKOUT CONFIG
        {
          path: "/checkout/:locationId",
          name: "NewCheckout",
          component: NewCheckout,
          props: true,
          meta: { authRequired: true },
        },
        {
          path: "pre-orders/:locationId",
          name: "PreOrdersCart",
          component: PreOrdersCart,
          props: true,
          meta: { authRequired: true },
        },
        {
          path: "/my-profile",
          component: MyProfileView,
          meta: { authRequired: true },
          children: [
            {
              path: "/",
              name: "MyProfile",
              component: MyProfile,
            },
          ],
        },
        {
          path: "/order/success",
          name: "CheckoutSuccess",
          component: CheckoutSuccess,
        },
        {
          path: "/user/my-profile",
          name: "PatientProfileInfo",
          component: PatientProfileInfo,
        },
        {
          path: "/privacy-policy",
          name: "Privacy",
          component: Privacy
        },
        {
          path: "/terms-of-service",
          name: "TermsOfService",
          component: TermsOfService
        },
        // END CHECKOUT CONFFIG
        // --------------------------------
      ],
    },
    {
      path: "/callback",
      name: "callback",
      component: AuthCallback,
    },
    {
      path: "/home-page",
      component: HomePageLayout,
      children: [
        {
          path: "/",
          name: "HomePage",
          component: HomePage,
        },
        {
          path: "/marketplace-v2",
          name: "MarketplaceV2",
          component: MarketplaceV2,
          meta: { locationRequired: true, marketplace: true },
        },
        {
          path: "/med-center",
          name: "MedCenter",
          component: MedCenter,
        },
        {
          path: "/intellipel",
          name: "Intellipel",
          component: Intellipel,
        },
        {
          path: "/practice-builder",
          name: "PracticeBuilder",
          component: PracticeBuilder,
        },
        {
          path: "/contact-us",
          name: "ContactUs",
          component: ContactUs,
        },
      ],
    },
    {
      path: "/practice-onboarding-setup",
      name: "OnboardingPractice",
      component: Onboarding,
      props: true,
      children: [
        {
          path: "/",
          name: "PracticeOnboardingForm",
          component: OnboardingForm,
          meta: { totalSteps: 6 },
          children: [
            {
              path: "business-information",
              name: "PracticeInformation",
              component: PracticeInformation,
              meta: { step: 1 },
            },
            {
              path: "address-information",
              name: "PracticeAddressInformation",
              component: AddressInformation,
              meta: { step: 2 },
            },
            {
              path: "business-associate-agreement",
              name: "BusinessAssociateAgreement",
              component: BusinessAssociateAgreement,
              meta: { step: 3 },
            },
            {
              path: "end-user-license-agreement",
              name: "PracticeEndUserLicenseAgreement",
              component: OnboardingEULA,
              meta: { step: 4 }
            },
            {
              path: "practice-account-setup",
              name: "PracticeUserAccountSetup",
              component: AccountSetup,
              meta: { step: 5 }
            },
            {
              path: "get-started",
              name: "PracticeGetStarted",
              component: GetStarted,
              meta: { step: 6, isPractice: true },
            },
          ],
        },
      ]
    },
    {
      path: "/vendor-onboarding-setup",
      name: "OnboardingVendor",
      component: Onboarding,
      props: true,
      children: [
        {
          path: "/",
          name: "OnboardingForm",
          component: OnboardingForm,
          meta: { totalSteps: 7 },
          children: [
            {
              path: "business-information",
              name: "VendorInformation",
              component: VendorInformation,
              meta: { step: 1 },
            },
            {
              path: "address-information",
              name: "AddressInformation",
              component: AddressInformation,
              meta: { step: 2 },
            },
            {
              path: "business-associate-agreement",
              name: "BusinessAssociateAgreement",
              component: BusinessAssociateAgreement,
              meta: { step: 3 },
            },
            {
              path: "vendor-agreement",
              name: "VendorLicenseAgreement",
              component: VendorLicenseAgreement,
              meta: { step: 4 },
            },
            {
              path: "end-user-license-agreement",
              name: "VendorEndUserSetup",
              component: OnboardingEULA,
              meta: { step: 5 }
            },
            {
              path: "account-setup",
              name: "VendorUserAccountSetup",
              component: AccountSetup,
              meta: { step: 6 },
            },
            {
              path: "get-started",
              name: "VendorGetStarted",
              component: GetStarted,
              meta: { step: 7, isVendor: true },
            },
          ],
        },
      ],
    },
    {
      path: "/vendor-onboarding-setup/set-appointment",
      name: "SetAppointment",
      component: SetAppointment,
    },
    {
      path: "/signup-options",
      name: "Signup",
      component: Signup,
    },
    {
      path: "/change-password/change",
      name: "SetPassword",
      component: SetPassword,
    },
    {
      path: "/create-password",
      name: "CreatePassword",
      component: CreatePassword,
    },
    {
      path: "/password-recovery",
      name: "PasswordRecovery",
      component: PasswordRecovery,
    },
    {
      path: "/404",
      name: "PageNotFound",
      component: NotFound,
    },
    {
      path: "*",
      beforeEnter() {
        window.location.href = "/404";
      },
    },
  ],
});
