import axios from "axios";

async function getAppCustomEnv() {
  return {
    SIMPATRA_APP_LAB_RESULT_URL: process.env.VUE_APP_LAB_RESULT_URL,
    SIMPATRA_SERVICE_URL: process.env.VUE_APP_API_ROOT,
    VUE_APP_SELF_ROOT: process.env.VUE_APP_SELF_ROOT,
  };
}

const authenticatedConfigForAPIRequest = () => {
  const config = {
    headers: {
      Accept: "application/json",
    },
  };

  if (localStorage.access_token) {
    config.headers.Authorization = `Bearer ${localStorage.access_token}`;
  }

  return config;
};

export default class BaseProxy {
  async getSimpatraLabResultUrl() {
    const appCustomEnv = await getAppCustomEnv();
    return `${appCustomEnv.SIMPATRA_APP_LAB_RESULT_URL}`;
  }

  async getSimpatraServiceUrl() {
    const appCustomEnv = await getAppCustomEnv();
    return `${appCustomEnv.SIMPATRA_SERVICE_URL}`;
  }

  async getSimpatraLabResultData(url) {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: "Basic bnRSSWZpUkxpQU5rWUxPVG9NUHM6KkA4VEdAWURKbCRpc0kjdCFyVTBpaVZ3UA==",
      },
    };
    return await axios.get(url, config);
  }

  async getSimpatraServiceData(url, queryParams, axiosConfig = {}) {
    const config = authenticatedConfigForAPIRequest();
    const appCustomEnv = await getAppCustomEnv();
    config.params = queryParams;
    config.origin = appCustomEnv.VUE_APP_SELF_ROOT;
    if (axiosConfig && axiosConfig.headers) {
      config.headers = axiosConfig.headers;
    }

    const response = await axios.get(url, config);
    if (response.status === 401) {
      localStorage.removeItem("exp");
      localStorage.removeItem("hydraState");
      localStorage.removeItem("access_token");
      localStorage.removeItem("id_token");
      window.reload();
    }
    return response;
  }

  async postSimpatraServiceData(url, payload, axiosConfig = {}) {
    const config = authenticatedConfigForAPIRequest();
    return await axios.post(url, payload, { ...config, ...axiosConfig });
  }

  async patchSimpatraServiceData(url, payload) {
    const config = authenticatedConfigForAPIRequest();
    return await axios.patch(url, payload, config);
  }

  async deleteSimpatraServiceData(url, queryParams) {
    const config = authenticatedConfigForAPIRequest();
    config.params = queryParams;
    return await axios.delete(url, config);
  }

  async putSimpatraServiceData(url, payload) {
    const config = authenticatedConfigForAPIRequest();
    config.headers["content-type"] = "application/json";
    return await axios.put(url, payload, config);
  }
}
