import jwtdecode from "jwt-decode";
import { isEmpty, get, filter, includes } from "lodash";
import store from "./store";
import userMe from "./services/user-me";
import initOrg from "./services/init-organization-info";
import USER_TYPE_CONSTANTS from "@/constants/user-type-constants";

export default function(router) {
  router.beforeEach(async (to, from, next) => {
    if (store.getters.getOffCanvasMenuStatus === true) {
      store.commit("toggleOffCanvasMenu", {
        toggle: false
      });
    }

    // ====================================================
    // Authentication Flow: https://docs.simpatra.fun/design/authentication/
    // ====================================================
    // If the user has "exp" in their localstorage (meaning we can assume they are or were logged in)
    const loginExpTime = localStorage.getItem("exp");
    const loginAccessToken = localStorage.getItem("access_token");
    const loginIdToken = localStorage.getItem("id_token");
    const selectedVendor = localStorage.getItem("selected_vendor");
    const hydraState = localStorage.getItem("hydraState");
    const expirationTimeIsEmpty = loginExpTime === null || loginExpTime === undefined;
    const accessTokenIsEmpty = loginAccessToken === null || loginAccessToken === undefined;
    const pageIsRequiredAuth = to.matched.some(record => {
      return record.meta.authRequired;
    });
    const isVendorPage = to.meta.checkVendorAccessibility;

    if (to.name === "callback" && hydraState === to.query.state) next();
    if (loginExpTime && loginAccessToken) {
      const tokenExpiration = Number(loginExpTime);
      const isTokenExpired = tokenExpiration < Math.floor(Date.now() / 1000);

      // If the token is expired and the route being navigated to requires authentication
      if (isTokenExpired) {
        localStorage.callbackRoute = to.path;
        await store.dispatch("clearLocalStore");
        next("/login");
      } else {
        // If the token is not expired yet and the route isn"t /callback (meaning we aren"t getting a new token)
        // AND the user is not currently logged in inside state, grab the token from localstorage and update state
        const decodedToken = jwtdecode(loginIdToken);
        const { userType } = decodedToken.sim.user;
        const isSimpatraSuperAdmin = get(decodedToken.sim, "simpatraAdmin", false);
        const isVendorUser = userType === USER_TYPE_CONSTANTS.vendorUser;
        const localStorageVendorId = localStorage.getItem("selected_vendor");
        const isVendorView = !["", null, undefined].includes(localStorageVendorId) || isVendorUser;

        if (isEmpty(store.getters["usersModule/getMyInfo"])) {
          const superAdminPermissionDetails = await store.dispatch("accountsModule/fetchSuperAdminPermissionDetails", decodedToken.sub);
          decodedToken.superAdminPermissionDetails = superAdminPermissionDetails;
          await store.dispatch("usersModule/initializeCurrentLoggedInInfo", {
            exp: loginExpTime,
            access_token: loginAccessToken,
            decodedToken
          });
          // Partially added setUserInfo here, need more time refactor the old code
          await store.dispatch("setUserInfo", decodedToken);
        }

        // VENDOR INITIALIZATION
        if (isVendorView) {
          await store.dispatch("vendorAccountsModule/fetchVendorInfo", localStorageVendorId);
        } else {
          // PRACTICE INITIALIZATION
          await initOrg.fetchOrgInitialDetails({
            userType,
            isSimpatraSuperAdmin
          });
        }

        await initOrg.initLocalStorageDetails({
          isVendorView,
          decodedToken,
          userType
        });

        // If the route is tagged as simpatraAdmin only and the user is **not** a simpatra admin
        // This is to make sure people don"t accidentaly end up where they shouldn"t
        const isSimpatraAdminPage = to.matched.some(record => record.meta.simpatraAdmin || record.meta.simpatraAdminAccessRequired);
        const isSimpatraAdminUser = userMe.getMyRoles().isSimpatraAdmin;
        if (isSimpatraAdminPage && !isSimpatraAdminUser) {
          next("/");
        } else {
          const hasVendorPageAccess = userType === USER_TYPE_CONSTANTS.vendorUser || userMe.getMyRoles().isSimpatraAdmin;
          if (isVendorPage) {
            if (hasVendorPageAccess) {
              next();
            } else {
              next("/");
            }
          } else {
            next();
          }
        }
      }
    } else if (pageIsRequiredAuth && (expirationTimeIsEmpty || accessTokenIsEmpty)) {
      // If the route requires authentication and "exp" is not set in localstorage
      // Since exp is set on the callback route - we can assume a user has some sort
      // of token (expired or not) if they have "exp" set
      localStorage.callbackRoute = to.path;
      next("/login");
    } else {
      next();
    }
  });
}

export function hasPatientChartAccess() {
  return userMe.getMyRoles().isSchedulingAdmin;
}
