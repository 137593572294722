import Vue from "vue";
import "@/styles/_config.scss";
import "./plugins/element";
import "./plugins/socialSharing";
import vueDebounce from "vue-debounce";
import Vuelidate from "vuelidate";
import VuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
// Vue Analytics
import VueAnalytics from "vue-analytics";
// FontAwesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import VSelect from "vue-select";
import VueCalendly from 'vue-calendly';

import Antd from "ant-design-vue";
import Print from "vue-print-nb";
import locale from "element-ui/lib/locale/lang/en";
import ElementUI from "element-ui";
import FontAwesomeIcons from "./utils/font-awesome-icons";
import store from "./store";
import router from "./router";
import App from "./App.vue";
import axios from "./lib/axios";
import i18n from "./plugins/i18n";
import "element-ui/lib/theme-chalk/index.css";
import "ant-design-vue/dist/antd.css";
import routerGuard from "./router-guard";

Vue.use(vueDebounce, {
  defaultTime: "700ms"
});
/**
 * VueLidate CONFIG
 */
Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
  i18n: false,
  // Define common validation messages.
  template: templates.singleErrorExtractor.foundation6,
  messages: {
    required: "{attribute} is required!",
    minLength: "Field {label} must be 5 symbols",
    minAge: "Minimum Age is 20 Years",
    ageLimit: "Maximum Age is 100 Years",
    min4Digits: "Must be 4 digits"
  }
});
// END

Vue.component("v-select", VSelect);

Vue.use(Print);
Vue.use(ElementUI, { locale });
Vue.use(Antd);
Vue.use(VueCalendly);

if (process.env.VUE_APP_ENVIRONMENT !== "development") {
  Sentry.init({
    dsn: "https://03c82de4f79146cca59253a3ac6a48b5@sentry.io/3649237",
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
    environment: process.env.VUE_APP_ENVIRONMENT
  });
}
library.add(FontAwesomeIcons);
Vue.prototype.$internalAxios = axios;

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;

if (process.env.VUE_APP_ENVIRONMENT === "production") {
  Vue.use(VueAnalytics, {
    id: "UA-160349330-1",
    router
  });
}

// PARTIAL FIX FOR ELEMENT UI ISSUE
ElementUI.Dropdown.methods.hide = function hide() {
  if (!this.triggerElm || this.triggerElm.disabled) return;
  this.removeTabindex();
  if (this.tabindex >= 0) {
    this.resetTabindex(this.triggerElm);
  }
  clearTimeout(this.timeout);
  this.timeout = setTimeout(
    () => {
      this.visible = false;
    },
    this.trigger === "click" ? 0 : this.hideTimeout
  );
};

// PARTIAL FIX FOR ELEMENT UI ISSUE
ElementUI.Dropdown.methods.hide = function hide() {
  if (!this.triggerElm || this.triggerElm.disabled) return;
  this.removeTabindex();
  if (this.tabindex >= 0) {
    this.resetTabindex(this.triggerElm);
  }
  clearTimeout(this.timeout);
  this.timeout = setTimeout(
    () => {
      this.visible = false;
    },
    this.trigger === "click" ? 0 : this.hideTimeout
  );
};

routerGuard(router);

new Vue({
  router,
  store,
  i18n,
  render: h => {
    return h(App);
  }
}).$mount("#app");

